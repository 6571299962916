import React, {useState} from "react";
import Layout from "../layout";
import Construction from "../components/construction";
import SetLang from "../components/setLang";
import SEO from "../components/seo";
import { ContactWidget } from "../components/ContactWidget";
import { ContactPopUp } from "../components/contactPopUp";


export const BlogPageTemplate = () => {
  return (
    <div>
      
    </div>
  );
};

const BlogPage = ({data}) => {

  const {
      title,
      description,
      keywords,
      language,
      redirects,
      minititle1,
      minititle2,
      bigtitle1,
      bigtitle2,
      button,
      background
    } = data.home.frontmatter;
    
    const [isContactWidgetOpen, setIsContactWidgetOpen] = useState(false);
    const changeOpenWidget = () => {
      setIsContactWidgetOpen(!isContactWidgetOpen);
    };

  return (
    <Layout>
      
      <SetLang language={language} link={redirects} />
      <SEO
        title={title}
        lang={language}
        description={description}
        keywords={keywords}
      />
      <Construction {
        ...{
          minititle1,
          minititle2,
          bigtitle1,
          bigtitle2,
          button,
          background,
          language
        }
      }></Construction>


      <div>
        <ContactWidget changeOpenWidget={changeOpenWidget}/>

        {isContactWidgetOpen == true ? (
          <>
           <ContactPopUp changeOpenWidget={changeOpenWidget} />
          </>
        ) : <></>}

      </div>

    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query BlogPage($id: String!, $language: String!) {
    home: markdownRemark(
      id: { eq: $id }
      frontmatter: { language: { eq: $language } }
    ) {
      frontmatter {
			      templateKey
            title
            description
            keywords
            language
            redirects
            minititle1
            minititle2
            bigtitle1
            bigtitle2
            button
            background {
            childImageSharp {
              fluid(srcSetBreakpoints: [1500], quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }

    }

  }
    
`;


