import React, {useEffect} from 'react'
import { 
    ConstructionContainer,
    Box,
    MiniTitleContainer,
    MiniTitle,
    BigTitleContainer,
    BigTitle,
    ButtonContainer,
    Button,
    RelojContainer,
    Reloj
 } from './constructionComponents'
import BackgroundImage from "gatsby-background-image";
import { Link } from '@reach/router';

const Construction = (props) => {

    useEffect(() => {

        if(typeof window !== "undefined") {
            
            if(props.language === "es") {
                window.location.href = "https://blog.dentalvipcaracas.com";
            }else {
                window.location.href = "https://blog.dentalvipcaracas.com/en/";
            }
        }
    },[]);

    return (
      <>
        <ConstructionContainer>
           
        </ConstructionContainer>
      </>  
    );
}

export default Construction;




