import styled from "styled-components";
import { rhythm, scale } from "../utils/typography";

export const ConstructionContainer = styled.section`

    background-color: #1c1c1c;
    color: #fff;


`;

export const Box = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MiniTitleContainer = styled.div`
     margin: 50px 0px 46px 0px;
     text-align: center;
     font-size: 19px;

     @media (min-width: 768px){
        margin: 212px 0px -181px 0px;
     }
`;

export const MiniTitle = styled.span`
    
    font-family: 'Roboto' !important;
   
`;

export const BigTitleContainer = styled.div`
    line-height: 1.2em;
    text-align: center;
    margin: -223px 0px 46px 0px;

    @media (min-width: 768px){
        margin: -459px 0px 46px 0px;
    }
`;

export const BigTitle = styled.h1`
    letter-spacing: 0.2em;

    @media (min-width: 768px){
        font-size: 85px;
    }
`;

export const ButtonContainer = styled.div`
    margin: 74px 0px 46px 0px;
    text-align: center;
    width: 100%;

    @media (min-width: 768px){
        margin: 265px 0px 100px 0px;
    }
`;

export const Button = styled.div`

    margin-left: auto;
    margin-right: auto;
    ${scale(-0.2)};
    text-transform: uppercase;
    font-weight: 700;
    background: transparent;
    min-width: 170px;
    color: #fff;
    padding: 10px 20px;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
    text-decoration: none;
    width: 177px !important;
    height: 47px !important;
    border: 1px solid #fff;
    white-space: nowrap;
`;


export const RelojContainer = styled.div`
    text-align: center;
`;

export const Reloj = styled.img`
    width: 292px;
    height: 290px;
    object-fit: cover;
    object-position: 50% 50%;

    @media (min-width: 768px){

        width: 650px;
        height: 650px;
    }
`;